import { Heading } from 'components/ui/Heading'
import { IconButton } from 'components/ui/IconButton'
import Link from 'next/link'
import cx from 'classnames'
import React from 'react'
import { Text } from 'components/ui/Text'
import { IconX } from 'components/icons/components/IconX'
import { IconLongArrowLeft } from 'components/icons/components/IconLongArrowLeft'

interface Props {
  backHref?: string
  title: React.ReactNode
  className?: string
  wrapperClassName?: string
  headerInfoClassName?: string
  backLabel?: string
  rightExtra?: React.ReactNode
  subTitle?: React.ReactNode
  disabled?: boolean
  onBack?: () => void
  extra?: React.ReactNode
  useXIcon?: boolean
  isFixed?: boolean
  size?: 'lg' | 'sm'
  scheme?: 'light' | 'primary'
}

export function Head(props: Props) {
  const {
    backHref,
    title,
    className,
    wrapperClassName,
    headerInfoClassName,
    backLabel = 'Trở về',
    onBack,
    rightExtra,
    subTitle,
    disabled,
    extra,
    useXIcon = false,
    isFixed = true,
    size = 'sm',
    scheme = 'light',
    ...rest
  } = props

  const backButton = (
    <IconButton
      className="left-2 !absolute center-y"
      label={backLabel}
      variant="link"
      scheme={scheme === 'light' ? 'primary' : 'white'}
      as="a"
      onClick={backHref ? undefined : onBack}
      Icon={useXIcon ? IconX : IconLongArrowLeft}
      disabled={disabled}
    />
  )

  return (
    <header
      {...rest}
      className={cx(
        {
          'fixed top-0 inset-x-0 z-20 ': isFixed,
        },
        wrapperClassName,
      )}
    >
      <div
        className={cx(
          'sm:container bg-white border-b border-v2-gray-25',
          {
            'bg-white': scheme === 'light',
            'bg-v2-primary-400': scheme === 'primary',
          },
          className,
        )}
      >
        <div
          className={cx(
            'relative h-16 flex-col flex justify-center pr-14',
            {
              'h-[64px]': !subTitle,
              'h-[65px]': subTitle,
              'pl-12': backHref,
              'pl-4': !backHref,
              '!pl-12': onBack,
            },
            headerInfoClassName,
          )}
        >
          <Heading
            v2TextColor={scheme === 'light' ? 'Gray-Dark' : 'White'}
            as="h5"
            v2Size={size === 'sm' ? 'lg' : '3xl'}
            className="font-bold"
            truncate
          >
            {title}
          </Heading>
          {subTitle ? (
            <Text
              as="div"
              v2TextColor={scheme === 'light' ? 'Gray-Dark' : 'White'}
              truncate
              fontSize="sm"
              className="mt-0.5"
            >
              {subTitle}
            </Text>
          ) : null}

          {backHref ? <Link href={backHref}>{backButton}</Link> : null}
          {!backHref && onBack ? backButton : null}

          {rightExtra ? (
            <div className="absolute right-3 center-y">{rightExtra}</div>
          ) : null}
        </div>
        {extra}
      </div>
    </header>
  )
}
